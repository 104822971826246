import React, { Component } from 'react';
import { navigate, Router, Link } from "@reach/router"

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const UserListWithEditor = () => (
  <div className='mt-8 mb-12'>
    <Router>
      <UserList path={ROUTES.ADMIN} />
      <UserItem path={ROUTES.ADMIN_DETAILS} />
    </Router>
  </div>
);

class UserListBase extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.users().on('value', snapshot => {
        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          users: usersList,
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <h2 className='text-lg mb-2 font-bold'>List of Users</h2>

        {loading && <div className='text-center italic text-2xl font-extrabold text-gray-500 px-4 py-6 sm:px-0'>Loading ...</div>}

        <table class="table-auto">
          <tbody>
            {users.map(user => (
              <tr>
                <td class="border px-4 py-2"><strong>User:</strong> {user.username}</td>
                <td class="border px-4 py-2"><strong>Email:</strong> {user.email}</td>
                <td class="border px-4 py-2"><strong>Company One:</strong> {user.company}
                  {user.companyTwo
                    ?
                    <span> / <strong>Company Two:</strong> {user.companyTwo}</span>
                    :
                    null
                  }
                  {user.companyThree
                    ?
                    <span> / <strong>Company Three:</strong> {user.companyThree}</span>
                    :
                    null
                  }
                </td>
                <td class="border px-4 py-2">
                  {user.email === 'val@webbroi.com'
                    ?
                    <p className='text-gray-400'>Edit</p>
                    :
                    <Link to={`${ROUTES.ADMIN}/${user.uid}`} state={{ user: user, username: user.username, company: user.company, companyTwo: user.companyTwo, companyThree: user.companyThree }} className='text-blue-900 underline'>
                      Edit
                    </Link>
                  }
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      username: null,
      company: null,
      companyTwo: null,
      companyThree: null,
      ...props.location.state,
      editMode: false,
      newUsername: null,
      newCompany: null,
      newCompanyTwo: null,
      newCompanyThree: null,
    };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({
      loading: true,
      username: this.props.username,
      company: this.props.company,
      companyTwo: this.props.companyTwo,
      companyThree: this.props.companyThree,
    });

    this.props.firebase
      .user(this.props.user)
      .on('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.user).off();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  onToggleEditMode = () => {
    this.setState(state => ({
      editMode: !state.editMode,
      newUsername: this.state.user.username,
      newCompany: this.state.user.company,
      newCompanyTwo: this.state.user.companyTwo,
      newCompanyThree: this.state.user.companyThree,
    }));
  };

  onChangeEditUsername = event => {
    this.setState({
      newUsername: event.target.value,
    });
  };

  onChangeEditCompany = event => {
    this.setState({
      newCompany: event.target.value,
    });
  };

  onChangeEditCompanyTwo = event => {
    this.setState({
      newCompanyTwo: event.target.value,
    });
  };

  onChangeEditCompanyThree = event => {
    this.setState({
      newCompanyThree: event.target.value,
    });
  };

  onEditAccount = (username, company, companyTwo, companyThree) => {
    this.props.firebase.user(this.state.user.uid).update({
      username: username,
      company: company,
      companyTwo: companyTwo,
      companyThree: companyThree,
    });
  };

  onSaveEditAccount = () => {
    this.onEditAccount(this.state.newUsername, this.state.newCompany, this.state.newCompanyTwo, this.state.newCompanyThree);

    this.setState({
      editMode: false,
      username: this.state.newUsername,
      company: this.state.newCompany,
      companyTwo: this.state.newCompanyTwo,
      companyThree: this.state.newCompanyThree,
    });
  };

  onDeleteUser = () => {
    this.props.firebase.user(this.state.user.uid).remove()
    .then(() => {
      navigate(ROUTES.ADMIN);
    })
    .catch(error => {
      this.setState({ error });
    });
  }

  render() {
    const { user, loading, editMode, username, company, companyTwo, companyThree, newUsername, newCompany, newCompanyTwo, newCompanyThree } = this.state;

    return (
      <div className='sm:w-full w-1/3'>
        <h2 className='text-xl mb-6'><strong>User Account:</strong> {username}</h2>
        {loading && <div>Loading ...</div>}

        {user && (
          <div className='flex flex-col'>
            <span className='mb-2'>
              <strong>ID:</strong> {user.uid}
            </span>

            <span className='mb-2'>
              <strong>E-Mail:</strong> {user.email}
            </span>

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Username
                </label>

                <input
                  aria-label='username'
                  type="text"
                  value={newUsername}
                  onChange={this.onChangeEditUsername}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Username:</strong> {username}
              </span>
            )}

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Company
                </label>

                <input
                  aria-label='company'
                  type="text"
                  value={newCompany}
                  onChange={this.onChangeEditCompany}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Company:</strong> {company}
              </span>
            )}

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Company Two
                </label>

                <input
                  aria-label='companyTwo'
                  type="text"
                  value={newCompanyTwo}
                  onChange={this.onChangeEditCompanyTwo}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Company Two:</strong> {companyTwo}
              </span>
            )}

            {editMode ? (
              <>
                <label className="font-body block text-gray-700 text-sm font-bold mb-2" htmlFor="company">
                  Company Three
                </label>

                <input
                  aria-label='companyThree'
                  type="text"
                  value={newCompanyThree}
                  onChange={this.onChangeEditCompanyThree}
                  className="font-body shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
              </>
            ) : (
              <span className='mb-2'>
                <strong>Company Three:</strong> {companyThree}
              </span>
            )}

            <div className='mt-6'>
              <span>
                {editMode ? (
                  <div className='flex'>
                    <button
                      type="button"
                      onClick={this.onSaveEditAccount}
                      className='group relative w-full flex justify-center py-2 px-4 mr-1 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                    >
                      Save
                    </button>

                    <button
                      type="button"
                      onClick={this.onToggleEditMode}
                      className='group relative w-full flex justify-center py-2 px-4 ml-1 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={this.onToggleEditMode}
                    className='group relative w-full flex justify-center py-2 px-4 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                  >
                    Edit Account
                  </button>
                )}
              </span>

              <span>
                <button
                  type="button"
                  onClick={this.onSendPasswordResetEmail}
                  className='group relative w-full flex justify-center py-2 px-4 border border-red-900 text-sm leading-5 font-medium rounded-md text-red-900 hover:text-white bg-white hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                >
                  Send Password Reset
                </button>
              </span>

              <span>
                <Link to='/admin'>
                  <button
                    type="button"
                    className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
                  >
                    Back to Admin Dashboard
                  </button>
                </Link>
              </span>
            </div>

            <div className='mt-12 border-t pt-6'>
              <span>
                <button
                  type="button"
                  onClick={this.onDeleteUser}
                  className='group relative w-full flex justify-center py-2 px-4 border border-gray-500 text-sm uppercase font-semibold leading-5 rounded-md text-gray-500 hover:text-white bg-white hover:bg-black focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out mb-3'
                >
                  Delete User
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

export default UserListWithEditor