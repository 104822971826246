import React, { Component } from 'react';
import { Router, Link } from "@reach/router"

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const UserListNew = () => (
  <div className='mt-12 mb-12'>
    <Router>
      <UserList path={ROUTES.ADMIN} />
      <UserItem path={ROUTES.ADMIN_DETAILS} />
    </Router>
  </div>
);

class UserListBase extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.users().on('value', snapshot => {
        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({
          users: usersList,
          loading: false,
        });
      });
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const { users, loading } = this.state;

    return (
      <div>
        <div className='sm:w-full w-1/3'>
          <Link to='/add-users'>
            <button className='group relative w-full flex justify-center py-2 px-4 mb-6 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'>
              Add New User
            </button>
          </Link>
        </div>

        <h2 className='text-lg mb-2 font-bold'>List of Users</h2>

        {loading && <div>Loading ...</div>}

        <table class="table-auto">
          <tbody>
            {users.map(user => (
              <tr>
                <td class="border px-4 py-2"><strong>User:</strong> {user.username}</td>
                <td class="border px-4 py-2"><strong>Email:</strong> {user.email}</td>
                <td class="border px-4 py-2">
                  <Link to={`${ROUTES.ADMIN}/${user.uid}`} state={{ user: user }} className='text-blue-900 underline'>
                    Details
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

class UserItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.props.firebase
      .user(this.props.user)
      .on('value', snapshot => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.user).off();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  render() {
    const { user, loading } = this.state;

    return (
      <div className='sm:w-full w-1/3 mt-12'>
        <h2 className='text-xl mb-6'><strong>User Account:</strong> {user.username}</h2>
        {loading && <div>Loading ...</div>}

        {user && (
          <div className='flex flex-col'>
            <span className='mb-2'>
              <strong>ID:</strong> {user.uid}
            </span>
            <span className='mb-2'>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span className='mb-4'>
              <strong>Username:</strong> {user.username}
            </span>
            <span>
              <button
                type="button"
                onClick={this.onSendPasswordResetEmail}
                className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out'
              >
                Send Password Reset
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const UserList = withFirebase(UserListBase);
const UserItem = withFirebase(UserItemBase);

export default UserListNew